// Import from libraries, assets, and components
import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { MessagePreview } from './messages/MessagePreview';
import { useUser } from "@clerk/clerk-react"; // Use useUser for Clerk integration
import { ProfileMenu } from "./ProfileMenu";
import { Logo } from './Logo';
import {
  MessageSquare,
  ShoppingBag,
  BookOpen,
  Calendar,
  User,
  ChevronDown,
  MessageCircle,
  ArrowDown,
  ArrowUp,
  Settings,
  LayoutDashboard,
  ShoppingCart,
  BarChart2,
  Home,
  Shield,
  AlertTriangle,
  Users,
  Milk,
  Flower,
  LeafyGreen,
  HeartHandshake,
  PersonStanding,
  Sprout,
  ShoppingBasket,
  Pizza,
  Palette,
  Stars,
  Users2,
  SearchIcon,
  Briefcase,
  Hexagon,
  CombineIcon,
  Menu
} from 'lucide-react';

const discussionCategories = [
  
  {
    title: 'Healthy Living',
    items: [
      { name: 'Compost', path: '/discussions/live/compost', icon: User },
      { name: 'Cooking', path: '/discussions/live/cooking', icon: User },
      { name: 'Fermented', path: '/discussions/live/fermented', icon: User },
      { name: 'Foraging', path: '/discussions/live/foraging', icon: User },
      { name: 'ZeroWaste', path: '/discussions/live/zerowaste', icon: User },
    ],
  },
  {
    title: 'Identification',
    items: [
      { name: 'Plants', path: '/discussions/id/plants', icon: User },
      { name: 'Trees', path: '/discussions/id/trees', icon: User },
      { name: 'Flowers', path: '/discussions/id/flowers', icon: User },
      { name: 'Mushrooms', path: '/discussions/id/mushrooms', icon: User },
    ],
  },
  {
    title: 'Growing',
    items: [
      {
        name: 'Permaculture',
        path: '/discussions/grow/permaculture',
        icon: User,
      },
      { name: 'Hydroponic', path: '/discussions/grow/hydroponic', icon: User },
      { name: 'Indoor Farm', path: '/discussions/grow/indoor', icon: User },
      { name: 'Bee Keeping', path: '/discussions/grow/bees', icon: Hexagon },
    ],
  },
]

const CommunityCategories = [
      {
        title: 'Community',
        items: [
          { name: 'Contribute', path: '/contribute', icon: HeartHandshake },
          { name: 'Gardens', path: '/discussions/gardens', icon: Flower },
          { name: 'Job Board', path: '/discussions/jobs', icon: Briefcase },
          { name: 'Farm Share', path: '/csa/farmshare', icon: User },
          { name: 'Our Mission', path: '/mission', icon: User },
          { name: 'Our Team', path: '/orginization', icon: User },
          
      ],
     
  },
];

const storeCategories = [
  {
    title: 'Food Products',
    items: [
      { name: 'Produce', path: '/Store/Produce', icon: LeafyGreen },
      { name: 'Livestock+', path: '/Store/livestock', icon: Milk },
      { name: 'Ready-to-Eat', path: '/Store/standin', icon: Pizza },
    
    ],
  },
  {
    title: 'Plants & Growing',
    items: [
      
      { name: 'Plants', path: '/Store/standin', icon: Flower },
      { name: 'Seeds', path: '/Store/standin', icon: Sprout },
      { name: 'Soil & Tools', path: '/Store/standin', icon: HeartHandshake },
      { name: 'Basket', path: '/Store/orders', icon: ShoppingBasket },
    ],
  },
  {
    title: 'Supplies',
    items: [
      
      { name: 'Artisans', path: '/Store/standin', icon: Palette },
      { name: 'Services', path: '/Store/standin', icon: PersonStanding },
    ],
  },
];


export function Navbar() {
  const [mode, setMode] = useState<'default' | 'Store' | 'pro' | 'admin'>(
    'default'
  );
  const [isBottom, setIsBottom] = useState(false);
  const [isDiscussionsOpen, setIsDiscussionsOpen] = useState(false);
  const [iscommunityOpen, setIscommunityOpen] = useState(false);
  const [isStoreOpen, setIsStoreOpen] = useState(false);

  const [isMessagesOpen, setIsMessagesOpen] = useState(false);
  const [showAccountMenu, setShowAccountMenu] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isHoveringNav, setIsHoveringNav] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const navigate = useNavigate();
  const { currentUser } = useUser();
  const navRef = useRef<HTMLDivElement>(null);


  const SearchBar = ({ onSearchButtonClick }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();
  
    const handleSearch = (e) => {
      e.preventDefault();
      onSearchButtonClick(); // Trigger additional logic if needed
      if (searchTerm.trim()) {
        navigate(`/search?query=${encodeURIComponent(searchTerm)}`);
      }
    };
  
    return (
      <form
        onSubmit={handleSearch}
        className="flex items-center space-x-2 text-white hover:text-emerald-500"
        style={{
          flex: '1 1 auto',
          maxWidth: 'calc(100% / 6)',
          minWidth: '60px',
        }}
      >
        {/* Search Icon */}
        <SearchIcon
          className="text-white"
          style={{
            width: 'clamp(12px, 3vw, 20px)',
            height: 'clamp(12px, 3vw, 20px)',
          }}
        />
        {/* Search Input */}
        <input
          type="text"
          placeholder="Find it here!"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="flex-grow bg-transparent text-sm text-white placeholder-gray-400 outline-none"
          style={{
            fontSize: 'clamp(11px, 1.1vw, 15.4px)',
          }}
        />
        {/* Submit Button */}
        <button
          type="submit"
          className="hidden md:inline text-white hover:text-emerald-500"
          style={{
            fontSize: 'clamp(11px, 1.1vw, 15.4px)',
          }}
        >
          Search
        </button>
      </form>
    );
  };




  useEffect(() => {
    const checkMobile = () => {
      const mobile = window.innerWidth < 768;
      setIsMobile(mobile);
      if (mobile) setIsBottom(true); // Default to bottom bar for mobile
      else setIsBottom(false);      // Default to top bar for desktop
    };
  
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    if (isMobile) return;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setIsVisible(currentScrollY <= lastScrollY);
      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY, isMobile]);

  const togglePosition = () => {
    if (isMobile) return; // Prevent toggling position on mobile
    setIsBottom(!isBottom);
    closeAllDropdowns();
  };

  const toggleMode = () => {
    const modes: ('default' | 'Store' | 'pro' | 'admin')[] = [
      'default',
      'Store',
      'pro',
      'admin',
    ];
    const currentIndex = modes.indexOf(mode);
    const nextMode = modes[(currentIndex + 1) % modes.length];
    setMode(nextMode);
    navigate(nextMode === 'default' ? '/' : `/${nextMode}`);
  };

  const closeAllDropdowns = () => {
    setIsDiscussionsOpen(false);
    setIscommunityOpen(false);
    setShowAccountMenu(false);
    setIsMessagesOpen(false);
  };
  const handleButtonClick = () => {
    closeAllDropdowns();
    // Any other logic you need to perform on button click
  };

  const navVariants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: isBottom ? 100 : -100 },
  };

  return (
    <motion.nav
      ref={navRef}
      onMouseEnter={() => setIsHoveringNav(true)}
      onMouseLeave={() => setIsHoveringNav(false)}
      className={`
        fixed ${isBottom ? 'bottom-0' : 'top-0'} 
        w-full z-50 
        ${
          mode === 'Store'
            ? 'bg-gradient-to-r from-green-600 to-sky-700'
            : mode === 'pro'
            ? 'bg-gradient-to-r from-sky-800 via-emerald-800 to-rose-900'
            : mode === 'admin'
            ? 'bg-gradient-to-r from-black via-rose-950 to-sky-950'
            : 'bg-gradient-to-r from-stone-950 via-stone-650 to-green-900'
        }
        text-white rounded-2xl mx-auto max-w-[98%] left-0 right-0 my-2 shadow-lg
      `}
      initial="visible"
      animate={!isMobile && !isHoveringNav && !isVisible ? 'hidden' : 'visible'}
      variants={navVariants}
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
    >
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
          <button
  onClick={() => {
    handleButtonClick();  // Close dropdowns
    toggleMode();          // Toggle mode functionality
  }}
  className="hidden md:mr-4 md:block"
>
  <Logo mode={mode} />
</button>

            
<Link
  to={mode === 'default' ? '/' : `/${mode}`}
  className={`flex items-center ${
    location.pathname === (mode === 'default' ? '/' : `/${mode}`)
      ? 'text-emerald-500'
      : 'text-white hover:text-emerald-500'
  }`}
  style={{
    flex: '1 1 auto',
    maxWidth: 'calc(100% / 6)',
    minWidth: '10px',
    marginRight: '-20px', // Minimal spacing, icons almost touch
  }}
>
  <Home
    className={`${
      location.pathname === (mode === 'default' ? '/' : `/${mode}`)
        ? 'text-emerald-500'
        : 'text-white'
    }`}
    style={{
      width: 'clamp(16px, 4vw, 30px)', // Larger size for this icon
      height: 'clamp(16px, 4vw, 30px)',
    }}
  />
</Link>


            <AnimatePresence mode="wait">
              {mode === 'default' && (
                <motion.div
                  key="default-links"
                  className="flex items-center space-x-4 md:space-x-10 ml-8"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 20 }}
                >
                <Link
  to="/membership"
  className={`flex items-center ${
    location.pathname === '/membership' ? 'text-emerald-500' : 'text-white hover:text-emerald-500'
  }`}
  onClick={handleButtonClick}
  style={{
    flex: '1 1 auto',
    maxWidth: 'calc(100% / 6)',
    minWidth: '10px',
    marginRight: '10px', // Minimal spacing
  }}
>
  <Stars
    className={`${
      location.pathname === '/membership' ? 'text-emerald-500' : 'text-white'
    }`}
    style={{
      width: 'clamp(16px, 4vw, 30px)', // Larger size for this icon
      height: 'clamp(16px, 4vw, 30px)',
    }}
  />
</Link>

<Link
  to="/marketplace"
  className={`flex items-center ${
    location.pathname === '/marketplace'
      ? 'text-emerald-500'
      : 'text-white hover:text-emerald-500'
  }`}
  onClick={handleButtonClick}
  style={{
    flex: '1 1 auto',
    maxWidth: 'calc(100% / 6)',
    minWidth: '10px',
    marginRight: '10px', // Small spacing between icons
  }}
>
  <ShoppingBag
    className={`${
      location.pathname === '/marketplace' ? 'text-emerald-500' : 'text-white'
    }`}
    style={{
      width: 'clamp(14px, 3vw, 24px)', // Slightly smaller icon size
      height: 'clamp(14px, 3vw, 24px)',
    }}
  />
  <span
    className="hidden md:inline"
    style={{
      fontSize: 'clamp(16px, 1.1vw, 14px)', // Responsive font size
      marginLeft: '4px',
    }}
  >
    Marketplace
  </span>
</Link>

<div className="relative">
  {/* Button Trigger */}
  <button
    className="flex items-center space-x-2 text-white hover:text-emerald-500"
    onMouseEnter={() => {
      closeAllDropdowns();
      setIscommunityOpen(true);
    }}
    onClick={() => {
      handleButtonClick(); // Close dropdowns and trigger other logic
      navigate('/contribute');
    }}
  >
    {/* User Icon */}
    <Users2
      className="text-white"
      style={{
        width: 'clamp(14px, 3vw, 20px)', // Shrinks on smaller screens
        height: 'clamp(14px, 3vw, 20px)',
      }}
    />
    {/* Text for Medium and Larger Screens */}
    <span
    className="hidden md:inline"
    style={{
      fontSize: 'clamp(16px, 1.1vw, 14px)', // Responsive font size
      marginLeft: '4px', // Small space between icon and text
    }}
  >Community</span>
    {/* Chevron Icon */}
    <ChevronDown
      className="text-white"
      style={{
        width: 'clamp(12px, 2.5vw, 18px)', // Shrinks on smaller screens
        height: 'clamp(12px, 2.5vw, 18px)',
      }}
    />
  </button>

  {/* Dropdown Menu */}
  <AnimatePresence>
    {iscommunityOpen && (
      <motion.div
        className={`absolute ${
          isBottom
            ? 'bottom-[calc(150%+0.75rem)]'
            : 'top-[calc(150%+0.75rem)]'
        } left-0 transform -translate-x-1 w-[200px] md:w-[300px] rounded-xl shadow-xl p-6 z-50 ${
          mode === 'Store'
            ? 'bg-gradient-to-r from-blue-900 to-indigo-900'
            : mode === 'pro'
            ? 'bg-gradient-to-r from-blue-900 to-indigo-900'
            : mode === 'admin'
            ? 'bg-gradient-to-r from-purple-900 to-pink-900'
            : 'bg-gradient-to-r from-stone-950 via-stone-950 to-green-900'
        }`}
        initial={{ opacity: 0, y: isBottom ? 10 : -10 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: isBottom ? 10 : -10 }}
        onMouseLeave={() => setIscommunityOpen(false)}
        style={{
          maxHeight: '90vh', // Ensures dropdown fits on the screen vertically
          overflowY: 'auto', // Adds scrolling if content exceeds max height
        }}
      >
        <div className="grid grid-cols-1 gap-8">
          {CommunityCategories.map((category, idx) => (
            <div key={idx}>
              {/* Category Title */}
              <h3 className="text-xl font-semibold text-emerald-500 mb-1">
                {category.title}
              </h3>
              {/* Dropdown Items */}
              {category.items.map((item, itemIdx) => (
                <Link
                  key={itemIdx}
                  to={item.path}
                  className="flex items-center space-x-2 px-2 py-1.5 rounded hover:text-emerald-600 text-lg text-white/90"
                  onClick={() => setIscommunityOpen(false)}
                >
                  <item.icon
                    className="text-white"
                    style={{
                      width: 'clamp(18px, 3.75vw, 30px)', // Increased size by 1.5x
                      height: 'clamp(18px, 3.75vw, 30px)',
                    }}
                  />
                  <span>{item.name}</span>
                </Link>
              ))}
            </div>
          ))}
        </div>
      </motion.div>
    )}
  </AnimatePresence>
</div>



<div className="relative">
  {/* Button Trigger */}
  <button
    className="flex items-center space-x-2 text-white hover:text-emerald-500"
    onMouseEnter={() => {
      closeAllDropdowns();
      setIsDiscussionsOpen(true);
    }}
    onClick={() => {
      handleButtonClick(); // Close dropdowns and trigger other logic
      navigate('/discussions');
    }}
  >
    {/* MessageSquare Icon */}
    <MessageSquare
       className="text-white"
       style={{
         width: 'clamp(14px, 3vw, 20px)', // Shrinks on smaller screens
         height: 'clamp(14px, 3vw, 20px)',
      }}
    />
    {/* Text for Medium and Larger Screens */}
    <span
    className="hidden md:inline"
    style={{
      fontSize: 'clamp(16px, 1.1vw, 14px)', // Responsive font size
      marginLeft: '4px',
    }}
  >
      Discussions
    </span>
    {/* Chevron Icon for Dropdown */}
    <ChevronDown
      className="hidden md:inline text-white"
      style={{
        width: 'clamp(12px, 2.5vw, 18px)', // Responsive size
        height: 'clamp(12px, 2.5vw, 18px)',
      }}
    />
  </button>

  {/* Dropdown Menu */}
  <AnimatePresence>
    {isDiscussionsOpen && (
      <motion.div
        className={`absolute ${
          isBottom
            ? 'bottom-[calc(150%+0.75rem)]'
            : 'top-[calc(150%+0.75rem)]'
        } left-0 transform -translate-x-1/2 w-[300px] md:w-[600px] rounded-xl shadow-xl p-6 z-50 ${
          mode === 'Store'
            ? 'bg-gradient-to-r from-blue-900 to-indigo-900'
            : mode === 'pro'
            ? 'bg-gradient-to-r from-blue-900 to-indigo-900'
            : mode === 'admin'
            ? 'bg-gradient-to-r from-purple-900 to-pink-900'
            : 'bg-gradient-to-r from-stone-950 via-stone-950 to-green-900'
        }`}
        initial={{ opacity: 0, y: isBottom ? 10 : -10 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: isBottom ? 10 : -10 }}
        onMouseLeave={() => setIsDiscussionsOpen(false)}
        style={{
          maxHeight: '90vh', // Ensure dropdown fits within the screen
          overflowY: 'auto', // Add scrolling if content overflows
        }}
      >
        <div className="grid grid-cols-2 md:grid-cols-3 gap-8">
          {discussionCategories.map((category, idx) => (
            <div key={idx}>
              {/* Category Title */}
              <h3 className="text-xl font-semibold text-emerald-500 mb-1">
                {category.title}
              </h3>
              {/* Dropdown Items */}
              {category.items.map((item, itemIdx) => (
                <Link
                  key={itemIdx}
                  to={item.path}
                  className="flex items-center space-x-2 px-2 py-1.5 rounded hover:text-emerald-600 text-md text-white/90"
                  onClick={() => setIsDiscussionsOpen(false)}
                >
                  <item.icon
                    className="text-white"
                    style={{
                      width: 'clamp(30px, 1.5vw, 36px)', 
                      height: 'clamp(30px, 1.5vw, 36px)',
                    }}
                  />
                  <span>{item.name}</span>
                </Link>
              ))}
            </div>
          ))}
        </div>
      </motion.div>
    )}
  </AnimatePresence>
</div>

<Link
  to="/learning"
  className={`flex items-center ${
    location.pathname === '/learning'
      ? 'text-emerald-500'
      : 'text-white hover:text-emerald-500'
  }`}
  onClick={handleButtonClick}
  style={{
    flex: '1 1 auto',
    maxWidth: 'calc(100% / 6)',
    minWidth: '10px',
    marginRight: '10px', // Small spacing between icons
  }}
>
  <BookOpen
    className={`${
      location.pathname === '/learning' ? 'text-emerald-500' : 'text-white'
    }`}
    style={{
      width: 'clamp(14px, 3vw, 24px)', // Slightly smaller icon size
      height: 'clamp(14px, 3vw, 24px)',
    }}
  />
  <span
    className="hidden md:inline"
    style={{
      fontSize: 'clamp(16px, 1.1vw, 14px)', // Responsive font size
      marginLeft: '4px',
    }}
  >
    Learning
  </span>
</Link>


<Link
  to="/events"
  className={`flex items-center ${
    location.pathname === '/events'
      ? 'text-emerald-500'
      : 'text-white hover:text-emerald-500'
  }`}
  onClick={handleButtonClick}
  style={{
    flex: '1 1 auto',
    maxWidth: 'calc(100% / 6)',
    minWidth: '00px',
    marginRight: '0px', // Small spacing between icons
  }}
>
  <Calendar
    className={`${
      location.pathname === '/events' ? 'text-emerald-500' : 'text-white'
    }`}
    style={{
      width: 'clamp(14px, 3vw, 24px)', // Slightly smaller icon size
      height: 'clamp(14px, 3vw, 24px)',
    }}
  />
  <span
    className="hidden md:inline"
    style={{
      fontSize: 'clamp(16px, 1.1vw, 14px)', // Responsive font size
      marginLeft: '4px',
    }}
  >
    Events
  </span>
</Link>


<div className="hidden sm:flex justify-center items-center">
  <div
    className="p-2 bg-gray-800/30 rounded-full shadow-md backdrop-blur-md"
    style={{
      maxWidth: "400px", // Adjust as needed
      width: "100%",
    }}
  >
    <SearchBar onSearchButtonClick={handleButtonClick} />
  </div>
</div>




               </motion.div>
               
              )}

              {mode === 'Store' && (
                <motion.div
                  key="Store-links"
                  className="flex items-center space-x-8 ml-8"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 20 }}
                >
                  <Link
                    to="/Store/Produce"
                    className="flex items-center space-x-2 text-white hover:text-emerald-500"
                  >
                    <ShoppingCart className="h-5 w-5" />
                    <span>My Orders</span>
                  </Link>

                  <div className="relative">
                  <button
  className="flex items-center space-x-2 text-white hover:text-emerald-500"
  onMouseEnter={() => {
    closeAllDropdowns();
    setIsStoreOpen(true);
  }}
  onClick={() => {
    handleButtonClick(); // Close dropdowns and trigger other logic
    navigate('/Store'); 
  }}
>
  <Menu className="h-8 w-8 md:h-5 w-5" />
  <span className="hidden md:inline">By Catagory</span>
  <ChevronDown className="hidden md:h-4 w-4" />
</button>

<AnimatePresence>
  {isStoreOpen && (
    <motion.div
      className={`absolute ${
        isBottom
          ? 'bottom-[calc(150%+0.75rem)]'
          : 'top-[calc(150%+0.75rem)]'
      }
        left-0 transform -translate-x-1/2 w-[600px] rounded-xl shadow-xl p-6 z-50
        ${mode === 'Store'
          ? 'bg-gradient-to-r from-blue-900 to-indigo-900'
          : mode === 'pro'
          ? 'bg-gradient-to-r from-blue-900 to-indigo-900'
          : mode === 'admin'
          ? 'bg-gradient-to-r from-purple-900 to-pink-900'
          : 'bg-gradient-to-r from-stone-950 via-stone-950 to-green-900'}
        hidden sm:block`} // Hide on mobile, show on small screens and up
      initial={{ opacity: 0, y: isBottom ? 10 : -10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: isBottom ? 10 : -10 }}
      onMouseLeave={() => setIsStoreOpen(false)}
    >
      <div className="grid grid-cols-3 gap-8">
        {storeCategories.map((category, idx) => (
          <div key={idx}>
            <h3 className="text-xl font-semibold text-emerald-500 mb-1">
              {category.title}
            </h3>
            {category.items.map((item, itemIdx) => (
              <Link
                key={itemIdx}
                to={item.path}
                className="flex items-center space-x-3 px-2 py-1.5 rounded hover:text-emerald-600 text-md text-white/90"
                onClick={() => setIsStoreOpen(false)}
              >
                <item.icon className="h-6 w-6" />
                <span>{item.name}</span>
              </Link>
            ))}
          </div>
        ))}
      </div>
    </motion.div>
  )}
</AnimatePresence>
                  </div>


                  <Link
                    to="/Store/Storefront"
                    className="flex items-center space-x-2 text-white hover:text-emerald-500"
                  >
                    <CombineIcon className="h-5 w-5" />
                    <span>HGM Storefront</span>
                  </Link>

                </motion.div>
                
              )}

              {mode === 'pro' && (
                <motion.div
                  key="pro-links"
                  className="flex items-center space-x-8 ml-8"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 20 }}
                >
                  <Link
                    to="/pro/dashboard"
                    className="flex items-center space-x-2 text-white hover:text-emerald-500"
                  >
                    <LayoutDashboard className="h-5 w-5" />
                    <span>Dashboard</span>
                  </Link>
                  <Link
                    to="/pro/orders"
                    className="flex items-center space-x-2 text-white hover:text-emerald-500"
                  >
                    <ShoppingCart className="h-5 w-5" />
                    <div className="relative">
                      <span>Orders</span>
                      <span className="absolute -top-1 -right-6 bg-red-500 text-white text-xs w-5 h-5 flex items-center justify-center rounded-full">
                        69
                      </span>
                    </div>
                  </Link>
                  <Link
                    to="/pro/analytics"
                    className="flex items-center space-x-2 text-white hover:text-emerald-500"
                  >
                    <BarChart2 className="h-5 w-5" />
                    <span>Analytics</span>
                  </Link>
                </motion.div>
              )}

              {mode === 'admin' && (
                <motion.div
                  key="admin-links"
                  className="flex items-center space-x-8 ml-8"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 20 }}
                >
                  <Link
                    to="/admin/Dashboard"
                    className="flex items-center space-x-2 text-white hover:text-emerald-500"
                  >
                    <Shield className="h-5 w-5" />
                    <span>Dashboard</span>
                  </Link>
                  <Link
                    to="/admin/users"
                    className="flex items-center space-x-2 text-white hover:text-emerald-500"
                  >
                    <Users className="h-5 w-5" />
                    <span>Users</span>
                  </Link>
                  <Link
                    to="/admin/reports"
                    className="flex items-center space-x-2 text-white hover:text-emerald-500"
                  >
                    <AlertTriangle className="h-5 w-5" />
                    <div className="relative">
                      <span>Reports</span>
                      <span className="absolute -top-1 -right-6 bg-red-500 text-white text-xs w-5 h-5 flex items-center justify-center rounded-full">
                        5
                      </span>
                    </div>
                  </Link>
                  <Link
                    to="/admin/settings"
                    className="flex items-center space-x-2 text-white hover:text-emerald-500"
                  >
                    <Settings className="h-5 w-5" />
                    <span>Settings</span>
                  </Link>
                </motion.div>
              )}
            </AnimatePresence>
          </div>










          <div className="flex items-center space-x-4 sm:space-x-6">
{/* Messages Button */}
<div
  className="relative flex-grow max-w-[100px] sm:max-w-[120px] lg:max-w-[150px]" // Dynamic scaling for width
>
  <button
    className="relative w-full p-2 hover:bg-white/10 rounded-lg flex items-center justify-center"
    onMouseEnter={() => {
      closeAllDropdowns();
      setIsMessagesOpen(true);
    }}
    onClick={() => {
      handleButtonClick();
      navigate("/messages");
    }}
  >
    <MessageCircle
      className="text-white"
      style={{
        width: "clamp(20px, 5vw, 32px)", // Icon scales dynamically
        height: "clamp(20px, 5vw, 32px)",
      }}
    />
    <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs w-5 h-5 flex items-center justify-center rounded-full">
      3
    </span>
  </button>

  <AnimatePresence>
    {isMessagesOpen && (
      <MessagePreview
        isBottom={isBottom}
        onMouseLeave={() => setIsMessagesOpen(false)}
        mode={mode}
      />
    )}
  </AnimatePresence>
</div>

{/* Profile Menu */}
<div className="relative flex-grow max-w-[100px] sm:max-w-[120px] lg:max-w-[150px]">
  <ProfileMenu />
</div>


            {!isMobile && (
              <button
                className="p-2 hover:bg-white/10 rounded-lg"
                onClick={togglePosition}
              >
                {isBottom ? (
                  <ArrowUp className="h-5 w-5 text-white" />
                ) : (
                  <ArrowDown className="h-5 w-5 text-white" />
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </motion.nav>
  );
}
